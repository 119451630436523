<template>
  <div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c9="" class="title">
            <h6 _ngcontent-c9="">实训基地</h6>
          </div>
          <div _ngcontent-c7="" class="site-banner">
            <img _ngcontent-c7="" alt="认证" src="../../assets/images/djt/renz_banner.png">
          </div>
          <!--  -->
          <div _ngcontent-c7="" class="authen-list">
            <div _ngcontent-c7="" class="courses-list-header">
              <ul _ngcontent-c7="" style="float:left">
                <li _ngcontent-c7="">分类:</li>
                <li _ngcontent-c7="" class="on">全部</li>
              </ul>
              <div _ngcontent-c7="" class="search_part">
              </div>
            </div>
            <div _ngcontent-c7="" style="clear: both;"></div>
            <div _ngcontent-c7="">
              <div _ngcontent-c7="" class="courses-list-content">
                <ul _ngcontent-c7="" class="page-one clearfix">
                  <li _ngcontent-c7="" v-for="(item, index) in zcjdList" :key="index">

                    <a _ngcontent-c7="" href="javascript:;">
                      <div _ngcontent-c7="" class="img"><img _ngcontent-c7="" alt="" :src="`/_fid/` + item.image"></div>
                      <div _ngcontent-c7="" style="padding: 0 5px">
                        <p _ngcontent-c7="" class="courses-name">{{item.title}}</p>
                        <p _ngcontent-c7="" class="courses-des">{{item.emphasis}}
                        </p>
                        <p _ngcontent-c7="" class="courses-price">
                          <!-- <img _ngcontent-c7="" alt=""  src="../../assets/images/money.png" style="width: 11px;height: 14px;margin-right: 2px;"> -->
                          ￥ {{item.money}}
                        </p>
                        <p _ngcontent-c7="" class="courses-teacher" @click="item.flag = true">去报名</p>
                      </div>
                      <div _ngcontent-c7="" class="hover-div" :class="item.flag? 'active' : ''" @mouseleave="item.flag = false">
                        <img _ngcontent-c7="" alt="" src="../../assets/images/aboutwe.png">
                        <p _ngcontent-c7="">关注公众号后进行报名</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div _ngcontent-c7="" style="clear:both;"></div>

              <div _ngcontent-c7="" class="page">
                <!-- <ul _ngcontent-c7="">
                  <li _ngcontent-c7="" class="prepage">
                    <img _ngcontent-c7="" alt="" src="./政策2_files/toleft.png">
                  </li>
                  <li _ngcontent-c7="" class="bing">1
                  </li>
                  <li _ngcontent-c7="">2
                  </li>
                  <li _ngcontent-c7="" class="nextpage">
                    <img _ngcontent-c7="" alt="" src="./政策2_files/toright.png">
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import { reactive, toRefs } from 'vue'

import { getArticleList } from "@/api/common.js"
export default {
  setup(props) {
    const state = reactive({
      zcjdList: []
    })
    const getArticle = (cmsTypeId) => {
      getArticleList({ current: 1, size: 100, status: 2, cmsTypeId: 4 }).then(res => {
        if (res.code === 20000) {
          state.zcjdList = res.result.records
          state.zcjdList.forEach(item => {
            item.flag = false
          })
        }
      })
    }
    getArticle()
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 60px 0;

  .activity {
    background: #fff;
    padding: 20px;
  }
}

.site-banner[_ngcontent-c7] {
  width: 100%;
  margin-top: 26px;
  height: 95px;
}

.title {
  margin-right: 7px;
  font-size: 18px;
  color: #4F17A8;

  h6 {
    font-size: 22px;
    margin-right: 7px;
  }
}


.authen-list[_ngcontent-c7]{width:100%;}
.courses-list-header[_ngcontent-c7]{font-size:16px;height:50px;width:100%;background:#f7f7f7;border:1px solid #dcdcdc;margin-top: 26px; margin-bottom: 26px;}
.courses-list-header[_ngcontent-c7]   li[_ngcontent-c7]{float: left;padding: 0 20px;line-height: 50px;cursor: pointer;}
.courses-list-header[_ngcontent-c7]   li[_ngcontent-c7]:nth-child(1){color: #000;font-weight: 600;margin-left: 20px;}
.courses-list-content[_ngcontent-c7]{width:100%;margin-top: 0px;height:19.5rem;}
.courses-list-content[_ngcontent-c7] li[_ngcontent-c7] {width:390px;height: 355px;margin-right: 15px;float: left;margin-bottom:20px;padding: 0px;cursor: pointer;}
.courses-list-content[_ngcontent-c7] li[_ngcontent-c7]:nth-child(3n) {margin-right: 0;}
.courses-list-content[_ngcontent-c7] .img[_ngcontent-c7]{width:100%;height:208px; }
.courses-list-content[_ngcontent-c7] .img[_ngcontent-c7] img[_ngcontent-c7]{width:100%;height:100%;}
.courses-list-content[_ngcontent-c7] {width:100%;margin-top:0px}
.courses-list-content[_ngcontent-c7] li[_ngcontent-c7]   a[_ngcontent-c7]{display: block;width:100%;font-size: 14px}
.courses-list-content[_ngcontent-c7] .courses-name[_ngcontent-c7]{width:92%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin:8px 0;}
.courses-list-content[_ngcontent-c7] .courses-des[_ngcontent-c7]{height:42px;line-height: 21px;overflow:hidden;margin-bottom: 10px;}
.courses-list-content[_ngcontent-c7] .courses-price[_ngcontent-c7]{color:#4F17A8;float: left;padding:.25rem 0; vertical-align: middle;}
.courses-list-content[_ngcontent-c7] .courses-teacher[_ngcontent-c7]{float: right;text-align: left;padding:.25rem 0;color:#4F17A8;}

.page-one[_ngcontent-c7] > li[_ngcontent-c7]:hover{
  
  box-shadow: 0px 0px 15px 0px #ccc;
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]   a[_ngcontent-c7]:hover   .hover-div[_ngcontent-c7]{
  
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]{
  position: relative;
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]   .hover-div[_ngcontent-c7]{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  text-align: center;
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]   .hover-div[_ngcontent-c7].active {
  display: block;
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]   .hover-div[_ngcontent-c7]   img[_ngcontent-c7]{
  width: 233px;
  height: 233px;
  margin: 42px 0 19px 0;
}
.page-one[_ngcontent-c7] > li[_ngcontent-c7]   .hover-div[_ngcontent-c7]   p[_ngcontent-c7]{
  height: 18px;
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
}

.page[_ngcontent-c7]{width:100%;margin-bottom: 1.3rem;text-align: center;}
.page[_ngcontent-c7]   ul[_ngcontent-c7]{display:inline-block;}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]{
  font-size: 16px;
  float: left;
  cursor: pointer;
  text-align: center;
  line-height: 34px;
  width: 44px;
  height: 34px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  margin: 0 5px;
}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:first-child, .page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:last-child{
  width: 64px;
  height: 34px;
}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:first-child{
  margin-right: 13px;
}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:last-child{
  margin-left: 13px;
}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:first-child   img[_ngcontent-c7]{
  margin-top: 8px;
  margin-left: 10px;
}
.page[_ngcontent-c7]   ul[_ngcontent-c7]   li[_ngcontent-c7]:last-child   img[_ngcontent-c7]{
  margin-top: 8px;
  margin-left: -10px;
}


.pilicy-title[_ngcontent-c6] {
  margin-top: 50px;
  width: 100%;
  height: 80px;
  position: relative;
}

.pilicy-title[_ngcontent-c6] .line[_ngcontent-c6] {
  width: 100%;
  height: 2px;
  background: #4F17A8;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
}

.pilicy-title[_ngcontent-c6] .innerbox[_ngcontent-c6] {
  width: 180px;
  height: 60px;
  background: #FFF;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

.pilicy-title[_ngcontent-c6] .innerbox[_ngcontent-c6] .title1[_ngcontent-c6] {
  height: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #4F17A8;
  line-height: 30px;
}

.pilicy-title[_ngcontent-c6] .innerbox[_ngcontent-c6] .title2[_ngcontent-c6] {
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}
</style>
